// Alpine
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import Plyr from 'plyr';

// Initialize alpine
Alpine.plugin(persist)

// Plugins
import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'

// Extend webpack window classes
window.Swiper = Swiper

// Add to window
window.Plyr = Plyr;
window.Alpine = Alpine;
window.Alpine.start();
